import React from 'react'
import CartOverview from '@cmp/templates/CartOverview'
import { useCartState, useCartActions } from '../context/Cart'

export default () => {
  const { cart } = useCartState()
  const { changeQuantity } = useCartActions()

  return <CartOverview cart={cart} onChangeQuantity={changeQuantity} />
}
